@media only screen and (min-width: 0) and (max-width: 640px) {
  div.tabs-header-box {
    width: 275px;
    border-bottom: none;
  }

  div.MuiBox-root > div.tabs-header > div.MuiBox-root {
    flex-direction: row;
    justify-content: center;
  }
}

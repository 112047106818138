body {
  margin: 0;
  background-color: #F1F1F1;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --primary-deep-blue-color: #193051;
  --secondary-deep-blue-color: #345c8b;
  --secondary-deep-blue-80-color: rgba(52, 92, 139, 0.8);
  --error-color: #c80000;
  --white-color: #ffffff;
  --white-80-color: rgba(255, 255, 255, 0.8);
  --black-40-color: rgba(0, 0, 0, 0.4);
}

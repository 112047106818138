.field-control {
  width: 100%;
}

.field-control > label {
  font-weight: 600;
  font-size: 14px;
  display: block;
  color: var(--white-color);
  margin-bottom: 3px;
}

.field-control input {
  font-size: 14px;
  width: calc(100% - 32px);
  height: 40px;
  line-height: 100%;
  padding: 0 16px;
  border-radius: 4px;
  border: none;
  outline: none;
  font-weight: 500;
}

.field-control input::-webkit-input-placeholder {
  /* Edge */
  color: #000 !important;
}

.field-control input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000 !important;
}

.field-control input::placeholder {
  color: #000 !important;
}

.field-control > input::placeholder {
  color: var(--black-40-color);
}

.helper-text-input{
  margin: 6px 0 0 0;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  width: 110%;
}
.sidebar-nav .nav-link {
  cursor: pointer;
}
.is-mobile {
  display: none;
}
@font-face {
  font-family: 'Righteous';
  src: url("assets/fonts/Righteous/Righteous-Regular.eot");
  src: url("assets/fonts/Righteous/Righteous-Regular.woff") format("woff"),
  url("assets/fonts/Righteous/Righteous-Regular.ttf") format("opentype"),
  url("assets/fonts/Righteous/Righteous-Regular.svg#filename") format("svg");
}
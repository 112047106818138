@media only screen and (min-width: 0) and (max-width: 640px) {
  div.box-learners-overview {
    padding: 15px 5px 15px 10px;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  span.MuiTabs-indicator {
    display: none;
  }

  div[role='tabpanel'] > .MuiBox-root {
    padding: 0;
  }

  div.tabs-header-box {
    width: 90%;
  }

  body:has(.box-learners-overview) {
    overflow: hidden;
  }
}

div.custom-chip {
  padding: 5px 20px;
  min-height: 30px;
  border-radius: 4px;
  text-overflow: ellipsis;
}

div.custom-chip.has-icon {
  padding-right: 5px;
}

.custom-chip > svg.MuiSvgIcon-root {
  cursor: default;
  margin: 0 0 0 10px;
}

.custom-chip > span {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
}

@media only screen and (min-width: 0) and (max-width: 640px) {
  .left-card-detail-mobile .card-detail-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 63px 0;
  }
  .card-detail-alert .alert-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 8px 6px;
    height: 17px;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .card-detail-mobile .card-detail-card-content {
    padding: 0;
    margin-top: 0;
    align-items: center ;
  }
  .card-detail-mobile .card-detail-card-content:last-child {
    margin-bottom: 17px;
  }
  .card-detail-mobile .card-detail-card-sub-content:last-child {
    padding-bottom: 0;
  }

  .card-detail-mobile .card-detail-card-sub-content {
    padding: 0 0 0 18px;
  }

  .card-detail-mobile .box-list-wrapper {
    box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.25);
  }

  .card-detail-mobile .box-list {
    padding: 0 10px;
    overflow-y: scroll;
    background-color: transparent;
    height: 512px;
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
  }

  .card-detail-mobile .card-detail-card-sub-content .training-name {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
  }

  .card-detail-mobile .card-detail-card-sub-content .course-name {
    font-size: 40px;
    margin-bottom: 10px;
    font-weight: 500;
    text-align: center;
  }

  .card-detail-mobile .card-detail-card-sub-content .quiz {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .card-detail-mobile .card-detail-card-sub-content .estimate-duration {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .card-detail-mobile .card-detail-card-sub-content .camp-end-date {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
  }

  .alert-item .compulsory {
    background-color: #00c6c8;
    color: white;
  }

  .alert-item .outstanding {
    background-color: #b00020;
    color: white;
  }

  .alert-item .cert {
    background-color: #dff4ed;
    color: #193051;
  }

  .left-card-detail-mobile .course-body,
  .left-card-detail-mobile .card-detail-mobile {
    padding: 0 23px;
  }

  .left-card-detail-mobile .course-body {
    margin-bottom: 17px;
  }

  .card-detail-card-sub-content .text-truncate {
    max-width: 582px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-detail-card-content {
    flex-direction: column;
    align-items: center;
  }

  .card-detail-card-content .card-detail-card-sub-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-detail-card-content .wrapper-mobile-detail {
    height: 150px;
    width: 150px;
    margin-bottom: 18px;
  }

  .card-detail-card-content .wrapper-mobile-detail .img-detail {
    height: 150px;
    width: 150px;
    margin-bottom: 18px;
  }
  .mobile-divider {
    margin-left: 23px;
    margin-right: 23px;
  }
  .mobile-divider hr{
    border-color: #B3B8B8;
  }
}

@media only screen and (min-width: 0) and (max-width: 640px) {
  .group-week-days {
    width: 42px;
    height: 66px;
  }
  .header-week-calendar .header-date-label {
    color: #345c8b;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 700;
  }
  .calendar .body-calendar-week .col {
    height: 66px;
  }
  .group-week-days .week-days {
    font-size: 12px;
  }
  .calendar .body-calendar-week .cell .number {
    font-size: 18px;
  }
  .calendar .body-calendar-week .selected .group-week-days .wapper-number .number {
    width: 30px;
    height: 33px;
  }
}

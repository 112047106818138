.policy-box-wrapper {
  margin-bottom: 63px;
}
.post-title {
  font-weight: 600 !important;
  text-transform: capitalize;
  color: #0e4f83;
}
.post-wrapper-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1.25rem;
}
.post-info {
  display: flex;
  flex-direction: column;
}

.post-info p {
  font-size: 1.125rem;
  font-weight: 500;
  color: #00000099;
}
.mt-sm {
  margin-top: 1.31rem;
}

.box-wrapper-content {
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3.75rem;
}
.post-content {
  font-size: 0.875rem !important;
  font-family: Montserrat;
  color: #000000e5 !important;
  font-weight: 400 !important;
  line-height: 160% !important;
}
.post-btn {
  padding: 0.5rem 0.9375rem !important;
  background-color: #345c8b !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  border-radius: 0.25rem !important;
  color: #ffffff !important;
  width: 9.0625rem;
  height: 2.5rem;
}

.post-btn:disabled {
  background-color: #cccccc !important;
}

.policy-text-mobile {
  height: 374px;
  overflow-y: scroll;
}
.email-policy, .email-policy:hover {
  color: #00000099;
  font-weight: 600;
  text-decoration: underline;
}
.policy-wrap-content{
  max-width: 1039px;
}
.policy-content {
  font-size: 17px !important;
}
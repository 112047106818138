@media only screen and (min-width: 0) and (max-width: 640px) {
    #simple-tabpanel-1 > div.MuiBox-root:has(.box-course-page-detail) {
        padding: 0;
        margin: 0;
    }

    body:has(.box-course-page-detail) {
        overflow: scroll;
    }

    [role='tabpanel'] {
        padding: 0 15px;
    }
}

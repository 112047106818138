.MuiPopover-root .filter-item .MuiCheckbox-root {
  position: inherit !important;
}

.MuiPopover-root .filter-item .Mui-checked,
.MuiPopover-root .filter-item .MuiCheckbox-indeterminate {
  color: #467599 !important;
}

.MuiPaper-root .MuiMenu-list li.clear-selected {
  border-top: 1px solid #e6e7e7;
  padding: 14px 16px 14px 8px;
}
.MuiPaper-root .MuiMenu-list li.clear-selected p {
  cursor: pointer;
}
.MuiListItem-root:focus-visible {
  border: unset;
  outline: none;
}

.MuiList-root .search-icon {
  position: absolute;
  right: 16px;
  color: #666666;
}

.MuiList-root .search-icon path {
  fill: #666666;
}

.search-input-item.MuiListItem-root {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border-radius: 0;
}

.search-input-item.MuiListItem-root fieldset {
  border: none;
  border-bottom: 1px solid #e0e0e0;
}

.search-input-item.MuiListItem-root input.MuiInputBase-input {
  font-size: 14px;
}
.MuiMenuItem-root .MuiFormControlLabel-label {
  font-size: 14px;
}

.MuiSelect-select .selected-total {
  background: #dff4ed;
  font-size: 13px;
  color: #193051;
  font-family: 'Montserrat';
  font-weight: 700;
  padding: 0px 6px;
  border-radius: 8px;
}
.dropdown-menu-list .MuiList-root.MuiMenu-list {
  padding: 0;
  max-height: 330px;
}

.search-input-item .search-outline-input {
  height: 48px;
}

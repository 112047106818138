.box-course-page-detail .btn-back-list .MuiButton-startIcon {
  margin-right: -4px;
}

.box-course-page-detail .btn-detail-start-courses {
  min-width: 230px;
  height: 40px;
  font-family: Montserrat;
}

a.MuiLink-root {
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

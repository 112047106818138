.completed_course {
  width: 100%;
  height: 100%;
}

.completed_course-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.completed_course-card .card_image {
}
.completed_course-card .card_content {
  color: #00166b;
  text-align: center;
  font-size: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  width: 530px;
}

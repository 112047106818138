.tabs-header .tabs-header-box .tabs-header-box-tabs .MuiTab-textColorPrimary {
  font-weight: 600;
  font-size: 16px;
  color: #666666;
}
.tabs-header .tabs-header-box .tabs-header-box-tabs .MuiTabs-indicator {
  background-color: #467599;
}

.tabs-header .tabs-header-box .tabs-header-box-tabs .Mui-selected {
  font-weight: 700;
  font-size: 16px;
  color: #467599;
}

.tabs-header .tabs-header-box .tabs-header-box-tabs .Mui-selected .MuiTab-iconWrapper {
  font-weight: 700;
  font-size: 14px;
  color: white;
  background-color: #345c8b;
  border-radius: 12px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 0px;
}
.tabs-header .tabs-header-box .tabs-header-box-tabs .MuiTab-iconWrapper {
  font-weight: 700;
  font-size: 14px;
  color: #1a2929;
  background-color: #e7ebf0;
  border-radius: 12px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px 0px;
}

.mobile-dropdown.MuiInputBase-root {
  color: #677070;
  font-weight: 500;
}

.mobile-dropdown.MuiInputBase-root:before {
  border-bottom: none;
}

.mobile-dropdown.MuiInputBase-root:after {
  border-bottom: none;
}

.mobile-dropdown.MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: none;
}

.tabs-header-box-tabs .tabs-header-box-tab{
  border-bottom: 1px solid #E0E0E0;
}


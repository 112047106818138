/* GRID */
.calendar-row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.row-middle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.col-start {
  justify-content: flex-start;
  text-align: left;
}

.col-center {
  justify-content: center;
  text-align: center;
}

.col-end {
  justify-content: flex-end;
  text-align: right;
  justify-content: end;
}

/* Calendar */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: #f2f3f3;
  margin-bottom: 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  min-height: 276px;
}

.body-calendar-week {
  background-color: #f2f3f3;
}

.body-calendar-week .calender-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar .header-week-calendar {
  font-weight: 700;
  font-size: 115%;
}

.calendar .header-week-calendar .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}

.calendar .header-week-calendar .icon:hover {
  transition: 0.25s ease-out;
  color: #345c8b;
}

.icon {
  width: 61px;
  height: 61px;
}

.icon > svg {
  width: 40px;
  height: 40px;
  color: #345C8B;
}

.col-end .Mui-disabled > svg {
  color: #99A0A0;
}

button.icon-previous {
  margin-right: 20px;
}

.calendar .body-calendar-week .cell {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.25s ease-out;
  font-size: 1.5em;
  color: #000000;
  height: 110px;
}

.calendar .body-calendar-week .today {
  border-bottom: 4px solid #244B77;
}

.calendar .body-calendar-week .cell:last-child {
  border-right: none;
}

.calendar .body-calendar-week .cell .number {
  position: absolute;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.calendar .body-calendar-week .cell .wapper-number {
  display: flex;
  align-items: center;
  height: 70px;
  justify-content: center;
  width: 70px;
  margin-top: 0;
  margin-bottom: 0;
}

.calendar .body-calendar-week .cell .wapper-number .point {
  border-radius: 50%;
  background-color: #ED552B;
  lineheight: 1px;
  width: 7px;
  height: 7px;
}

.calendar .body-calendar-week .selected .wapper-number {
  color: #fff;
}

.calendar .body-calendar-week .disabled {
  pointer-events: none;
}

.calendar .body-calendar-week .col {
  flex-grow: 0;
  flex-basis: calc(100% / 6);
  width: calc(100% / 6);
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
}
.header-week-calendar.calendar-row.flex-middle.text-footer {
  border-bottom: none;
  border-top: none;
  padding: 20px 0 15px 0;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 400;
}

.header-week-calendar.calendar-row.flex-middle {
  padding: 15px 0 20px 0px;
}

.header-week-calendar .header-date-label {
  color: #345c8b;
  font-family: 'Montserrat';
  font-size: 50px;
  font-weight: 700;
  margin-left: 7%;
}

.group-week-days {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.group-week-days .week-days {
  text-align: center;
  font-family: 'Montserrat';
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
}
.calendar .body-calendar-week .selected .group-week-days {
  border-radius: 100px;
  background-color: #e7ebf0;
}

.calendar .body-calendar-week .selected .group-week-days.past-months {
  border-radius: 100px;
  background-color: #e6e7e7;
}

.calendar .body-calendar-week .group-week-days.past-months .week-days {
  /*color: #919292;*/
  color: #000000;
  opacity: 0.6;
}

.calendar .body-calendar-week .selected .group-week-days .week-days {
  color: #345c8b;
}

.calendar .body-calendar-week .selected .group-week-days.past-months .week-days {
  color: #4D5858;
  opacity: 1;
}

.calendar .body-calendar-week .group-week-days.past-months .wapper-number .number {
  color: #000000;
  opacity: 0.6;
}

.calendar .body-calendar-week .selected .group-week-days.past-months .wapper-number .number {
  color: #fff;
  background-color: #4D5858;
}

.calendar .body-calendar-week .selected .group-week-days .wapper-number .number {
  border-radius: 50%;
  background-color: #345c8b;
  width: 50px;
  height: 55px;
  display: flex;
  justify-content: center;
}

.calendar .body-calendar-week .non-cursor {
  cursor: not-allowed;
}

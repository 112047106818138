@media only screen and (min-width: 0) and (max-width: 640px) {
  .login-body {
    width: 294px;
    margin: 0 auto;
    align-items: start;
    margin-top: 68px;
  }
  .login-page .login-header {
    display: none;
  }

  .login-page .login-logo {
    display: table;
    padding: 5px 22px;
    margin-bottom: 36px;
  }

  .login-logo img {
    width: 100%;
    max-height: 185px;
  }

  .login-page {
    background-color: var(--primary-deep-blue-color);
    display: flex;
    flex-direction: column;
  }

  .login-page:after {
    background: none;
  }
}

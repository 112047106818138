@media only screen and (min-width: 0) and (max-width: 640px) {
    .card-detail .is-mobile-course-detail {
        display: none;
    }

    .modal-dialog-course-detail [role='dialog'] {
        margin: 10px;
        max-height: calc(100% - 20px);
        border-radius: 3.2px;
    }

    .modal-wrapper {
        display: inline-flex;
        height: 1149px;
        flex-direction: column;
        gap: 10px;
        background: white;
        border: none;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .exit-block-wrapper {
        display: flex;
        padding: 15px 15px 15px 0px;
        justify-content: flex-end;
        align-items: center;
    }

    .exit-block-wrapper .exit-button {
        display: flex;
        align-items: center;
        min-width: 45px;
        height: 45px;
        padding: 5px;
        border-radius: 3.2px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 0px 3.2px 0px rgba(0, 0, 0, 0.25);
    }

    .exit-block-wrapper .exit-button .close-icon {
        font-size: 25px;
    }

    .left-card-detail-mobile {
        /*padding: 30px 23px;*/
    }

    .card-detail .box-list {
        padding: 0;
        height: 50vh;
        margin-left: auto;
    }
}

@media only screen and (min-width: 0) and (max-width: 640px) {
    .expandable-mobile-chip-cell > div.custom-chip.MuiChip-root {
        max-width: 80%;
        max-height: 44px;
        padding: 5px 5px 5px 20px;
    }

    .expandable-mobile-chip-cell > div.custom-chip.expanded-chip.MuiChip-root {
        max-width: 90%;
    }

    .expandable-mobile-chip-cell > div.custom-chip > span.MuiChip-label {
        min-width: 100px;
        max-height: 34px;
        text-overflow: ellipsis;
    }

    #course-results-data-container > .mobile-data-table > .mobile-data-cell-group > .mobile-basic-cell {
        border-top: none;
    }

    #course-results-data-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 40px;
        height: 80vh;
    }

    #course-results-data-container > .mobile-data-table {
        width: 90%;
        margin-top: 0;
    }

    .expandable-mobile-chip-cell {
        height: 44px;
        padding: 5px 8px;
        border-bottom: 1px solid #e6e7e7;
    }
}

.tab-header-title {
  margin-bottom: 65px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #345C8B;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
  margin-top: 40px;
}

.box-learners-overview {
  background-color: white;
  /*height: 500px;*/
  padding: 20px;
}

.box-learners-overview .tabs-header .MuiBox-root {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.box-learners-overview .tabs-header [role='tabpanel'] .MuiBox-root {
  padding: 15px 0 10px 0;
}

.box-learners-overview .tabs-header .MuiBox-root .MuiTypography-root {
  width: 100%;
}

.box-learners-overview .tabs-header-box{
  background: #fff;
}

.box-learners-overview .search-menu {
  margin-bottom: 10px;
}

.box-learners-overview .data-tables-general{
  background: #fff;
}

.box-learners-overview .tabs-header{
  background: #fff;
}


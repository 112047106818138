@media only screen and (min-width: 0) and (max-width: 640px) {
  .course-wrapper-elements {
    width: 100%;
    margin-bottom: 0;
  }
  .course-wrapper-elements .course-header {
    align-items: center;
    gap: 10px;
  }
  .course-wrapper-elements .course-infos {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin: 15px 0 0 0;
  }
  .course-wrapper-elements .course-infos .course-info {
    flex-direction: row;
    align-items: flex-start;
  }
  .course-wrapper .btn-wrapper {
    width: 100%;
    margin: -15px 0 0 0;
  }
  .btn-wrapper .course-card-btn {
    width: 100%;
  }
  .course-wrapper {
    flex-wrap: wrap;
    margin: 10px 15px 15px 15px;
  }

  .course-infos .course-info{
    gap:18px;
  }
  .course-header .course-thumbnail {
    width: 75px;
    height: 75px;
    border-radius: 10px;
  }

  .course-wrapper .btn-wrapper .grade-btn{
    text-align: center;
  }
  .course-infos-wrapper{
    display: block;
    flex-direction: unset;
  }
}

.pagination-custom-wrapper {
  margin: 15px 0 0 0;
  padding: 10px;
}

.pagination-custom {
  display: flex;
  align-items: center;
  font-family: 'Montserrat';
  color: #000000e5;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}

.pagination-custom .pagination-total-rows {
  margin: 0 30px;
}

.pagination-custom .pagination-selection {
  font-family: 'Montserrat';
  font-weight: 500;
  border: 1px solid #cccfcf;
  border-radius: 4px;
  color: #4c4c4c;
  height: 32px;
  background: #fff;
  font-size: 12px;
}

.pagination-selection .MuiSelect-select {
  padding: 5px 9px 5px 10px;
}

.pagination-selection .MuiSelect-select.MuiOutlinedInput-input {
  padding-right: 33px;
}

.pagination-selection svg.MuiSelect-icon {
  color: black;
}

.pagination-numbers .first-page,
.pagination-numbers .previous-page,
.pagination-numbers .next-page,
.pagination-numbers .last-page {
  position: relative;
  top: 0px;
  padding: 0;
}

.pagination-custom .pagination-numbers {
  display: flex;
  padding: 0 14px 0 0;
  align-items: center;
  list-style-type: none;
}

.pagination-custom .pagination-numbers .first-page {
  margin-right: 20px;
  cursor: pointer;
}

.pagination-custom .pagination-numbers .last-page {
  cursor: pointer;
}

.pagination-custom .pagination-numbers .previous-page {
  margin-right: 24px;
  cursor: pointer;
}

.pagination-custom .pagination-numbers .next-page {
  margin-right: 20px;
  cursor: pointer;
}

.pagination-numbers li.disabled {
  cursor: unset;
  color: rgba(0, 0, 0, 0.18);
  fill: rgba(0, 0, 0, 0.18);
  pointer-events: none;
}

.pagination-custom .pagination-numbers .first-page.Mui-disabled,
.pagination-custom .pagination-numbers .previous-page.Mui-disabled,
.pagination-custom .pagination-numbers .next-page.Mui-disabled ,
.pagination-custom .pagination-numbers .last-page.Mui-disabled{
  cursor: unset;
  color:#0000002e;
  fill: #0000002e;
  pointer-events: none;
}

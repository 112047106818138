@media only screen and (min-width: 0) and (max-width: 640px) {
    .course-result-title {
        display: none;
    }

    #grades-data-container.data-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 40px;
        padding: 15px 10px;
        height: 80vh;
    }

    #grades-data-container > .MuiStack-root > .mobile-data-cell-group {
        border-bottom: 1px solid #b3b8b8;
        padding: 24px 17px;
    }

    .mobile-basic-cell {
        padding: 10px 6px;
    }

    #grades-data-container > .mobile-data-table {
        width: 80%;
    }

    body:has(#grades-data-container) {
        overflow: hidden;
    }

    .mobile-actions-cell {
        margin-top: 20px;
    }
}

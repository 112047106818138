/*    CONTAINER   */
.login-page {
  height: calc(100vh - 45px);
  min-height: calc(100vh - 45px);
  background-color: var(--primary-deep-blue-color);
  display: flex;
  flex-direction: column;
}

.login-page:after {
  content: '';
  background: url('../../assets/images/waves.png') no-repeat;
  position: absolute;
  width: 100%;
  height: 329px;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-background-size: cover;
  background-size: cover;
}

/*     HEADER     */
.login-page .login-header {
  position: absolute;
  top: 40px;
  left: 44px;
}

.login-page .header-img {
  max-width: 100%;
  max-height: 100%;
}

/*     BODY       */
.login-body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.login-page .body-content {
  width: 100%;
  max-width: 400px;
}

.login-page .login-logo {
  margin-bottom: 58px;
  text-align: center;
  flex-direction: row;
  justify-content: center;
}

.login-page .form-control {
  width: 100%;
}

/*     FOOTER     */
.footer {
  color: var(--white-color);
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
}

/*     BUTTON     */
.btn {
  border: none;
  outline: none;
  display: block;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  height: 40px;
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--white-color);
  background-color: var(--secondary-deep-blue-color);
  transition: all 200ms;
}

.btn-login {
  width: 100%;
  color: var(--white-color);
  background-color: var(--secondary-deep-blue-color) !important;
  padding: 8px 15px;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
}

.btn:hover {
  cursor: pointer;
  background-color: var(--secondary-deep-blue-80-color);
  color: var(--white-80-color);
}

.login-page .login-logo .logo__img {
  max-width: 286px;
  height: 225px;
}

.btn-sso {
  width: 100%;
  background-color: var(--white-color) !important;
  color: var(--secondary-deep-blue-color) ;
  padding: 8px 15px;
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 600;
}
.btn-login.btn-to-Verification-code .MuiLoadingButton-loadingIndicatorCenter, .btn-sso .MuiLoadingButton-loadingIndicatorCenter{
  width: 100%;
  display: flex;
  justify-content: center;
  color: #CCCCCC;
}
.btn-login.btn-to-Verification-code:disabled, btn-sso:disabled{
  background-color: white !important;
}
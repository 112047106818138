@media only screen and (min-width: 0) and (max-width: 640px) {
    div.box-course-page-detail {
        margin-bottom: 67px !important;
        width: 87%;
    }

    .box-course-page-detail .btn-detail-start-courses {
        min-width: 100%;
    }

    .card-detail {
        padding-bottom: 0;
    }

    .card-detail .card-detail-card-content:last-child {
        padding-bottom: 0;
    }
}

.catalogue-container .catalogue-infinite-scroll{
    /*margin-left: 36px;*/
    /*margin-right: 36px;*/
}

.catalogue-container .catalogue-infinite-scroll{
    padding: 20px;
    height: 713px;
    overflow: auto;
    display: flex;
    gap: 30px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.catalogue-container .catalogue-infinite-scroll::-webkit-scrollbar {
    /*width: 5px;*/
}
.catalogue-container .catalogue-infinite-scroll::-webkit-scrollbar-track {
    background: transparent;
}
.catalogue-container .catalogue-infinite-scroll::-webkit-scrollbar-thumb {
    background: #C1C1C1;
}
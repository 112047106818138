.card-title-overview {
  font-size: 18px !important;
  font-weight: 500 !important;
  color: #000000e5;
  line-height: 22px;
}

.card-content-overview {
  font-size: 16px !important;
  font-weight: 400 !important;
  font-weight: 400;
  line-height: 25px;
}
.download-certificate-file {
  display: none;
}

.card-actions-button-wrapper .get-cert-button {
  margin-top: 68px;
}

.card-actions-button-wrapper .feedback-on-course-button {
  color: #345c8b;
}

.card-detail-card-sub-content .card-actions-button-wrapper button {
  width: 220px;
}




.course-result-title {
    margin-bottom: 65px;
    margin-top: 40px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    color: #467599;
    text-transform: capitalize;
    font-family: 'Montserrat';
}

.grade-cell {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    white-space: normal;
}

.btn-course-result-general {
    width: 106px;
    height: 40px;
}

.btn-course-result-general.btn-overview-course-result,
button.btn-overview-course-result {
  color: #345c8b;
  border: 1px solid #e0e0e0;
}

.btn-course-result-general.btn-cert-course-result,
button.btn-cert-course-result {
  background: #345c8b;
  color: white;
  margin-left: 9px;
}

.status-tooltip {
    margin: -10px 0 0 5px;
}

.btn-course-result-container .Mui-disabled.btn-cert-course-result{
    background-color:transparent !important;
}

@media only screen and (min-width: 640px) and (max-width: 1366px) {
    .status-icon {
        padding-right: 30px;
    }
}

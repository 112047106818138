@media only screen and (min-width: 1366px) and (max-width: 1366px) {
  .card-detail-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 18px;
  }
  .card-detail-card-sub-content .card-actions-button-wrapper {
    display: flex;
  }
  .card-detail-card-sub-content {
    display: flex;
    flex-direction: column;
  }
  .card-detail-card-content .training-name,
  .card-detail-card-content .course-name {
    text-align: center;
  }
  .card-detail-card-content .training-name{
    margin-top: 18px;
  }
  .wrapper-info{
    margin-bottom: 80px;
  }
  .training-name-wrapper {
    width: 100%;
    text-align: left;
  }
  .card-detail-card-sub-content .wrapper-info {
    width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .card-detail-card-sub-content .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 1440px) {
  /*.card-detail-card-content .training-name,*/
  /*.card-detail-card-content .course-name {*/
  /*  text-align: center;*/
  /*}*/
  .card-detail-card-sub-content {
    align-items: center;
  }
  /*.card-detail-card-sub-content .card-actions-button-wrapper {*/
  /*  flex-direction: column;*/
  /*  display: flex;*/
  /*}*/
  .card-detail-card-sub-content .card-actions-button-wrapper button {
    width: 220px;
  }
  .card-detail-card-sub-content .card-actions-button-wrapper .get-cert-button {
    margin: 5px 0 0 0;
  }
  .card-detail-card-sub-content .wrapper-info {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .card-detail-card-sub-content .text-truncate {
    max-width: 500px;
  }


}

@media only screen and (max-width: 1439px) {
  .course-card-style {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0.875rem !important;
    border: 1px solid #cccfcf;
    margin-bottom: 40px !important;
  }
  .course-wrapper {
    margin: 18px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .course-wrapper-elements {
    margin-bottom: 10px;
  }

  .course-wrapper-elements .course-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
  }
  .course-header .course-thumbnail {
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    border-radius: 0.625rem;
    width: 5.625rem;
    height: 5.625rem;
    border-radius: 10px;
  }

  .course-wrapper .course-infos {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    margin: -30px 0 0 120px;
    flex-wrap: wrap;
    width: 100%;
  }

  .course-infos .due-date {
    display: flex;
    gap: 5px;
    margin: 0 20px 0 0;
  }

  .course-infos .due-date .calendar-icon {
    color: #193051;
  }

  .course-infos .due-date .date-round-trip {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #00000099;
  }

  .course-infos .box-due-date {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
  }

  .course-infos .course-status {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }

  .course-infos .date-month {
    font-size: 0.875rem;
    text-align: center;
    color: #677070;
    display: inline-block;
  }

  .course-contain .title {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 160%;
  }

  .course-contain .sub-title {
    font-size: 0.875rem;
    margin-top: 0.1rem;
    color: #00000099;
  }

  .course-status .outstanding {
    color: #b00020;
  }
  .course-status .completed {
    color: #8ab409;
  }

  .course-status .assigned {
    color: #193051;
  }

  .course-card-action:hover {
    background: #c1cddb;
  }

  .course-wrapper .btn-wrapper .course-card-btn {
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    color: #345c8b;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 0.94rem;
    width: 100%;
  }

  .course-wrapper .btn-wrapper .grade-btn {
    color: #345c8b;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 0.94rem;
    width: 100%;
  }
  .course-contain {
    width: 100%;
    position: relative;
    display: inline-block;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1366px) {
  .box-course-page-detail .card-detail-card-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 18px;
  }
  .box-course-page-detail .card-detail-card-sub-content .card-actions-button-wrapper {
    display: flex;
  }
  .box-course-page-detail .card-detail-card-sub-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .box-course-page-detail .card-detail-card-content .training-name,
  .box-course-page-detail .card-detail-card-content .course-name {
    text-align: start;
  }
}

.dropdown-menu.notifications,
.dropdown-menu.profile {
  display: block;
  transform: translate(-25px, 35px);
}
.dropdown-menu.profile {
  transform: translate(-96px, 38px);
}

.header-nav .nav-item {
  cursor: pointer;
}

.header {
  padding-right: 20px;
  display: flex;
  align-items: center;
  /*width: 100%;*/
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
}

#header {
  background-color: #193051;
}

.header-right {
  padding: 0 14px;
  display: flex;
  height: 32px;
  position: relative;
  z-index: 2;
  flex-direction: row-reverse;
}
.header .profile-block {
  display: flex;
  align-items: center;
}

.full-name {
  display: flex;
  color: #fff;
  margin-left: 13px;
  align-items: center;
  cursor: default;
  font-family: 'Righteous';
  font-size: 14px;
}

.arrow-drop-down-white {
  width: 18px;
  height: 18px;
  display: flex;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

.profile-avatar {
  width: 40px;
  margin-right: 10px;
}

/*.profile-avatar .profile-picture {*/
/*  width: 100%;*/
/*}*/

.profile-picture {
  margin-left: 10px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.img-hub {
  margin-right: 5px;
}

.btn-navigation {
  margin: 0 16px;
  display: inline-block;
  border-radius: 4px;
  color: white;
}

.btn-navigation-mobile {
  display: inline-block;
  border-radius: 4px;
  color: white;
}

.btn-navigation span {
  margin-right: 8px;
  display: flex;
}

.btn-navigation button,
.btn-navigation-mobile button {
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.btn-navigation-mobile.active,
.btn-navigation.active,
.btn-navigation button:hover {
  background: #fff;
  color: #193051;
}

.btn-navigation-mobile.active svg path,
.btn-navigation.active svg path,
.btn-navigation:hover svg path {
  fill: #193051;
}

.header-center {
  position: absolute;
  width: calc(100% - 40px);
  text-align: center;
  z-index: 1;
}

.btn-navigation-mobile button:disabled svg path,
.btn-navigation button:disabled svg path {
  fill: #ffffff7f;
}

.btn-navigation-mobile button:disabled,
.btn-navigation button:disabled {
  color: #ffffff7f;
  border: none !important;
}

.header .header-badge-notification-mobile {
  display: none;
}

#notification-menu .MuiList-root {
  min-width: 300px;
  max-width: 300px;
}

.card-detail {
  padding: 15px 11px 0px 5px;
}
.card-detail .card-detail-card-content:last-child {
  padding-bottom: 17px;
}
.card-detail .card-detail-card-sub-content:last-child {
  padding-bottom: 0;
}

.card-detail .card-detail-card-sub-content {
  padding: 0 18px;
}
.card-detail .card-detail-alert .MuiAlert-message {
  padding: 0;
  width: fit-content;
  font-weight: 500;
}

.card-detail .box-list-wrapper {
  padding: 0 0 5px 0;
  box-shadow: 0px 4px 4px -3px #00000040;
}

.card-detail .box-list {
  padding: 0 10px;
  overflow-y: scroll;
  background-color: transparent;
  height: 524px;
}
.card-detail .box-list:last-child {
  margin: 0 0 0 0;
}
.card-detail .box-list::-webkit-scrollbar {
  width: 3px;
}
.card-detail .box-list::-webkit-scrollbar-track {
  background: transparent;
}

.card-detail .box-list::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.card-detail .card-detail-card-sub-content .training-name {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #00000099;
}

.card-detail .card-detail-card-sub-content .course-name {
  font-size: 40px;
  margin-bottom: 13px;
  font-weight: 500;
  line-height: 64px;
}

.card-detail .card-detail-card-sub-content .quiz {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
}

.card-detail .card-detail-card-sub-content .cert {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #00000099;
}

.card-detail .card-detail-card-sub-content .estimate-duration {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #00000099;
}

.card-detail .card-detail-card-sub-content .estimate-duration .timelapse {
  color: #193051;
}

.card-detail .card-detail-card-sub-content .camp-end-date {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #808888;
}

.card-detail .card-detail-card-sub-content .camp-end-date .insert-invitation {
  color: #193051;
}

.card-detail-alert .compulsory {
  background-color: #00c6c8;
  color: white;
  margin-bottom: 2px;
}

.card-detail-alert .outstanding {
  background-color: #b00020;
  color: white;
}

.card-detail-alert .cert {
  background-color: #dff4ed;
  color: #193051;
}

.card-detail-alert .assigned {
  background-color: #193051;
  color: #ffffff;
}

.card-detail-alert .completed {
  background-color: #bde541;
  color: #193051;
}

.card-detail-alert .no-cert {
  background-color: #fff;
  color: #b3b8b8;
  border-bottom: 1px solid #b3b8b8;
  border-radius: 0px;
}

.card-detail-alert .optional {
  background-color: #fab005;
  color: #193051;
}

.card-detail-alert .refresher {
  background-color: #008545;
  color: #ffffff;
}

.card-detail .is-mobile-course-detail {
  display: block;
}

.card-detail .wrapper-detail {
  height: 760px;
  overflow-y: scroll;
}

.course-card-style {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0.875rem !important;
  border: 1px solid #cccfcf;
  margin-bottom: 40px !important;
  background: white;
}

.course-wrapper {
  margin: 18px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course-wrapper-elements {
  /*width: 380px;*/
}
.course-wrapper-elements .course-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 30px;
}
.course-header .course-thumbnail {
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  width: 5.625rem;
  height: 5.625rem;
  margin-top: 8px;
}

.course-wrapper .course-infos {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 119px;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.course-infos .due-date {
  display: flex;
  gap: 5px;
  margin: 0 20px 0 0;
}

.course-infos .course-info {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.course-infos .due-date .calendar-icon {
  color: #193051;
}

.course-infos .due-date .date-round-trip {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #00000099;
}

.course-contain .title {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 160%;
}

.course-contain .sub-title {
  font-size: 0.875rem;
  margin-top: 0.1rem;
  color: #00000099;
}

.course-infos .box-due-date {
  display: flex;
  align-items: center;
  gap: 5px;
}

.course-infos .course-status {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.course-infos .date-month {
  font-size: 0.875rem;
  text-align: center;
  color: #677070;
  display: inline-block;
}

.course-status .outstanding {
  color: #b00020;
  width: 68px;
  height: 68px;
}
.course-status .completed {
  color: #8ab409;
  width: 68px;
  height: 68px;
}

.course-status .assigned {
  color: #193051;
  width: 68px;
  height: 68px;
}

.course-card-action:hover {
  background: #E7EBF0;
}
.course-card-action:hover .course-card-btn,
.course-card-action:active .course-card-btn {
  background: white;
}

.course-wrapper .btn-wrapper .course-card-btn {
  border: 1px solid #e0e0e0;
  border-radius: 0.25rem;
  color: #345c8b;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem 0.94rem;
}

.course-wrapper .btn-wrapper .grade-btn {
  color: #345c8b;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem 0.94rem;
}

.course-card-action.active {
  background-color: #c1cddb;
}

.course-card-action.active .course-card-btn {
  background-color: white;
}

.box-elements .text-truncate {
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.course-header-wrapper-img {
  -webkit-border-radius: 0.625rem;
  -moz-border-radius: 0.625rem;
  border-radius: 0.625rem;
  width: 5.625rem;
  height: 4.625rem;
}

.course-infos-wrapper {
  display: inline-flex;
  flex-direction: column;
}

.multiline-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* start showing ellipsis when 1rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}
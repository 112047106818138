@media only screen and (min-width: 0) and (max-width: 640px) {
    #onboard-data-container > .MuiStack-root  > div.mobile-data-cell-group {
        border-bottom: none;
        padding: 10px;
    }

    .chip-cell {
        align-items: center;
        border-bottom: 1px solid #e6e7e7;
        padding: 10px 0 5px;
    }

    div.MuiAvatar-root {
        height: 45px;
        width: 45px;
    }

    #onboard-data-container.data-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    #onboard-data-container > .mobile-data-table {
        width: 90%;
        margin-top: 0;
    }

    .tabs-header-box {
        margin-top: -60px;
    }
}

.timeout-page {
  height: calc(100vh - 45px);
  min-height: calc(100vh - 45px);
  background-color: var(--primary-deep-blue-color);
  display: flex;
  flex-direction: column;
}

.timeout-page:after {
  content: '';
  background: url('../../assets/images/waves.png') no-repeat;
  position: absolute;
  width: 100%;
  height: 329px;
  bottom: 0;
  left: 0;
  z-index: 1;
  -webkit-background-size: cover;
  background-size: cover;
}

.timeout-page .body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  z-index: 2;
}

.timeout-page .body-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  max-width: 300px;
}
.timeout-page .body-content .title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
  margin-bottom: 18px;
  font-family: Montserrat;
  color: #dff4ed;
}

.timeout-page .body-content .guideline-text {
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  color: #ededed;
  margin-bottom: 4px;
}

.timeout-page .body-content .portal-name {
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  color: #ffffff;
}

.timeout-page .logo-timeout {
  margin-bottom: 18px;
  text-align: center;
}

.timeout-whitespace-nowrap{
  white-space: nowrap;
}

.timeout-page .timeout-header{
  position: absolute;
  top: 40px;
  left: 44px;
}


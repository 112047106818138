.notfound {
  position: relative;
  background-color: #1b304f;
  height: 100vh;
  /*margin-top: 20px;*/
}

.center {
  display: flex;
  flex-direction: column;
  width: 34%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 9999;
  animation: mover 1s ease-in;
}

.wave {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@keyframes mover {
  0% {
    top: 0;
  }
}

.card-catalogue{
    font-family: 'Montserrat', sans-serif;
    width: 261px;
    height: 360px;
    border: 1px solid #EDEDED;
    box-shadow: unset !important;
}
.card-catalogue .card-catalogue-img{
    max-width: 261px;
    height: 244px;
    /*object-fit: fill;*/
}

.card-catalogue .card-catalogue-content{
    margin: 15px 23px;
    padding: 0 !important;
}
.card-catalogue .card-catalogue-content .card-catalogue-title{
    font-size: 16px;
    font-weight: 700;
    line-height: 25.4px;
}
.card-catalogue .card-catalogue-content .card-catalogue-subtitle{
    font-size: 14px;
    font-weight: 600;
    line-height: 25.4px;
    color: #99A0A0;
}
.card-catalogue .card-catalogue-content .card-catalogue-button {
    margin-top: 14px;
    width: 100%;
}
.card-catalogue .card-catalogue-content .card-catalogue-date{
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    color: #808888;
    margin-top: 12px;
}
.card-catalogue .catalogue-icon-wrapper{
    position: relative;
    width: 100%
}
.card-catalogue .catalogue-icon{
    background-color: white;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    position:absolute;
    right: 23px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-catalogue .multiline-ellipsis {
    overflow: hidden;
    display: inherit;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* start showing ellipsis when 1rd line is reached */
    white-space: nowrap;
    overflow-wrap: break-word; /* break word with long word */
    width: 231px;
    text-overflow: ellipsis;
}
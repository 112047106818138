@media only screen and (min-width: 0) and (max-width: 640px) {
  .is-mobile {
    display: block;
  }

  .header-logo {
    display: none;
  }

  .header.fixed-top {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
  }

  .hamburger-menu {
    background: url('../images/hamburger-icon.svg') no-repeat;
    width: 20px;
    height: 13px;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    cursor: pointer;
    z-index: 10;
  }

  .header .header-right {
    display: block;
    background: #fff;
    position: fixed;
    width: calc(100% - 40px);
    height: 100%;
    top: 0;
    left: -100%;
    padding: 35px 20px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }

  .header .header-right.show {
    left: 0;
  }

  .header .header-center button {
    text-indent: -99999px;
    width: auto;
    min-width: 60px;
    padding: 5px 10px;
  }
  .header .header-center .btn-navigation span {
    margin-right: 0;
  }

  .tabs-header {
    padding-top: 60px;
  }

  .header .workspace-block {
    margin-top: 25px;
  }

  .header .profile-block {
    width: 100%;
  }

  .header .profile-block .profile-picture {
    width: 40px;
    margin: 0;
  }

  .header .profile-block .full-name {
    font-family: Montserrat;
    font-size: 14px;
    color: #193051;
    text-transform: capitalize;
    font-weight: 700;
    margin-left: 12px;
  }

  .header .dropdown-menu {
    display: none;
  }

  .header .logout-mobile {
    margin-top: 8px;
    cursor: pointer;
  }

  .header .close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }

  .header .menu-bottom-mobile {
    position: absolute;
    bottom: 75px;
    width: calc(100% - 30px);
    left: 15px;
  }

  .header .menu-bottom-mobile img {
    width: 90px;
  }
  .header .header-badge-notification {
    display: none;
  }
  .header .header-badge-notification-mobile {
    left: 12%;
    display: inline-flex;
  }

  footer {
    height: 60px;
    font-weight: 400;
    padding: 0;
  }
}

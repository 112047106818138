.box-learners-overview .data-tables-general .course-result-header-start {
    padding: 0px 0px 0px 74px;
}

.box-learners-overview .data-tables-general .course-result-cell-start{
    padding: 22px 0 22px 45px;
}
.box-learners-overview .data-tables-general .course-result-cell-start .course-result-cell-email{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    color: #467599;
}
.onboarding-overview-wrapper {
  margin: 10px 20px 10px 20px;
}

.onboarding-overview-wrapper .privacy-policy-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e6e7e7;
  margin: 20px 20px 10px 18px;
}

.onboarding-overview-wrapper .privacy-policy-wrapper p {
  font-weight: 600;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #000000e5;
  line-height: 19px;
}

.box-learners-overview .data-tables-general .data-tables-header-cell-start {
  padding: 0px 0px 0px 74px;
}

.box-learners-overview .data-tables-general .data-tables-header-cell-end {
  margin-top: 22px;
}

.box-learners-overview .on-boarding-cell {
  padding: 22px 0 22px 45px;
}

.box-learners-overview .on-boarding-cell .on-boarding-user-name {
  width: 45px;
  height: 45px;
}
.box-learners-overview .on-boarding-cell .on-boarding-cell-email{
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  color: #467599;
}
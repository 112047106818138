.dashboard-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 44px auto 0;
}

.dashboard-page .card-title {
  color: #808888;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin: 0 0 5px 0;
  padding: 0 !important;
}

.dashboard-page .card-subtitle {
  color: #808888;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.dashboard-page .box-list {
  padding: 0 10px;
  overflow-y: scroll;
  background-color: transparent;
  height: 385px;
}

.dashboard-page > .MuiGrid-root {
    justify-content: center;
}

/*.box-wrapper-content .policy-wrap-content{*/
/*  height: 500px;*/
/*  overflow-y: scroll;*/
/*}*/

/*.box-wrapper-content .policy-wrap-content::-webkit-scrollbar {*/
/*  width: 3px;*/
/*}*/
/*.box-wrapper-content .policy-wrap-content::-webkit-scrollbar-track {*/
/*  background: transparent;*/
/*}*/

/*.box-wrapper-content .policy-wrap-content::-webkit-scrollbar-thumb {*/
/*  background: #c1c1c1;*/
/*  border-radius: 4px;*/
/*}*/

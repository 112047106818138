.workspace-icon svg {
  width: 25px;
  height: 25px;
  margin-top: -2px;
}
.workspace-block {
  display: flex;
}
.workspace-block .workspace-icon.active {
  background-color: #345c8b;
}